.footer {
    /* background-color: #1AB3C5; */
    background-color: green;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 3.2vh;
}

.copyright {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    color: white;
    background-color: green;
    width: 100%;
    text-shadow: 0px 4px 4px#ffdd40;
    font-family: Inter;
    font-size: 18px;
    font-weight: 500;
    line-height: normal;
}

.link {
    color: white;
    font-family: Inter;
    background-color: green;
    text-decoration: none;
    font-size: 18px;
    margin-left: 10px;
}

@media (max-width: 600px) {

    .copyright, .link {
        font-size: 14px;
    }
}