.contact-page {
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 88vh;
}

.mail {
    display: flex;
    flex-direction: column;
    width: 600px;
    background-color: green;
    border-radius: 30px;
    align-items: center;
    height: 200px;
    justify-content: center;
}

.mail h1,
.social h1 {
    background-color: green;
    color: #ffdd40;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    margin-top: 16px;
    font-family: "Josefin Sans";
}

.email-container {
    color: white;
    background-color: green;
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 30px;
    font-size: 40px;
}

.social {
    height: 200px;
    display: flex;
    flex-direction: column;
    width: 600px;
    background-color: green;
    border-radius: 30px;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.social-icons {
    background-color: green;
    display: flex;
    flex-direction: column;
}

.instagram a {
    background-color: green;
    color: white;
}

.instagram {
    color: #DD2A7B;
    background-color: green;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    font-size: 40px;
}

.facebook {
    color: #3B5998;
    background-color: green;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-top: 12px;
    font-size: 40px;
}

.facebook a {
    background-color: green;
    color: white;
}

.contact-col {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}

@media (max-width: 600px) {
    .contact-page {
        height: auto;
    }

    .mail,
    .social {
        width: 360px;
        height: 250px;
    }

    .email-container {
        font-size: 28px;
    }

    .contact-page div {
        margin: 10px 0;
    }
}