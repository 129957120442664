@import url('https://fonts.googleapis.com/css2?family=Satisfy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Satisfy&display=swap');
*{
    background-color: black;
    font-family: "Satisfy";
}

.homepage{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    flex-wrap: wrap;
}

.home-img{
    width: 440px;
    z-index: 0;
}

.home-img2{
    width: 160px;
    z-index: 1;
    position: absolute;
    left: 340px;
    top: 310px;
}

.homepage-column1{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;
    flex-wrap: wrap;
}

.homepage-column2{
    display: flex;
    flex-direction: column;
}

.header h1{
    color: green;
    font-size: 140px;
}

.header h4{
    color: #ffdd40;
    font-size: 44px;
}

.header p {
    color: white;
    font-size: 30px;
    font-family: "Satisfy";
}

@media (max-width: 768px) {

    .homepage{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        height: 100%;
        margin-top: 10px;
        margin-bottom: 60px;
    }

    .home-img{
        width: 350px;
    }

    .home-img2{
        width: 140px;
        position: absolute;
        left: 220px;
        top: 294px;
    }

    .header h1{
        color: green;
        font-size: 50px;
        flex-wrap: nowrap;
        margin-top: 10px;
        padding: 0 0px;
        display: flex;
        justify-content: center;
    }

    .header h4{
        color: #ffdd40;
        font-size: 30px;
        padding: 0 20px;
    }

    .header p {
        width: 370px;
        color: white;
        font-size: 20px;
        padding: 0px 20px;
    }
}
