@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans&family=Satisfy&display=swap');

.about-page {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.about-page h1 {
    display: flex;
    justify-content: center;
    font-size: 60px;
    color: green;
}

.about-col1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: whitesmoke;
    width: 500px;
}

.about-col1 p {
    font-family: "Josefin Sans";
    font-size: 28px;
}

.about-col2 h3 {
    font-size: 40px;
}

.about-col1 h3 {
    font-size: 40px;
}

.about-col2 p {
    font-family: "Josefin Sans";
    font-size: 22px;
}

.about-col2 h4 {
    font-family: "Josefin Sans";
    color: #ffdd40;
    margin-bottom: 6px;
    margin-top: 6px;
}

.about-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.about-row h3 {
    color: #ffdd40;
}

.about-col2 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    color: whitesmoke;
    width: 510px;
}

.about-img{
    width: 450px;
    padding-left: 20px;
}

.about-img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0; 
    transition: opacity 0.7s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .about-img-caption {
    color: white;
    font-size: 20px;
    text-align: center;
    padding: 10px;
    font-family: "Josefin Sans";
  }
  
  .about-img-container {
    position: relative;
    width: 500px;
    margin-top: 20px;
    overflow: hidden;
  }
  
  .about-img-container:hover .about-img-overlay {
    transform: translateY(0);
    opacity: 0.8; 
    width: auto;
    text-align: center;
  }
  
  @media (max-width: 600px) {
    .about-page{
        height: auto;
    }
    .about-page h1 {
        font-size: 30px;
    }
    .about-col1 p,
    .about-col2 p {
        font-size: 16px; 
    }

    .about-col1,
    .about-col2{
        width: 350px;
        padding: 0px 20px;
    }

    .about-img{
        width: 350px;
    }

    .about-img-container{
        width: 350px;
    }

    .about-col2 h3,
    .about-col1 h3 {
        font-size: 20px; 
    }

    .about-img-caption {
        font-size: 16px; 
    }
}