.works-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
}

.works-page h1 {
    font-size: 80px;
    color: #ffdd40;
    margin-bottom: 20px;
}

.works {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.works li {
    margin: 10px;
    padding: 10px;
    position: relative;
    overflow: hidden;
    transition: transform 0.2s;
}

.works li:hover {
    transform: scale(1.08);
}

.image-container {
    position: relative;
}

.image-container img {
    max-width: 100%;
    height: auto;
    display: block;
    width: 400px;
}

.description-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: black;
    padding: 10px;
    color: #fff;
    font-size: 14px;
    opacity: 0;
    transform: translateY(100%);
    transition: transform 0.6s, opacity 0.6s;
}

.works li:hover .description-container {
    transform: translateY(0);
    opacity: 0.8;
    width: auto;
    text-align: center;
}

.works h2 {
    /* color: white; */
    color: #ffdd40;
    font-size: 40px;
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 400px;
}

.works p {
    font-size: 16px;
}

.description {
    color: white;
    font-family: "Josefin Sans";
    font-size: 10px;
    background-color: black;
}

.works-detail p {
    color: white;
    font-size: 30px;
}

.works-page h1 {
    font-size: 42px;
    color: #ffdd40;
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .image-container img {
        max-width: 360px;
        display: block;
    }

    .works li {
        margin: 10px;
        width: 360px;
    }

    .works-detail p {
        font-size: 16px;
    }

    .works h2 {
        color: #ffdd40;
        font-size: 26px;
        width: 350px;
    }

    .works-page h1 {
        font-size: 40px;
        margin-top: 20px;
        margin-bottom: 0px;
    }
}